
import { defineComponent, PropType } from 'vue';
import initSearch from '@/components/view/installer/batch-payments/init-search';
import { HeadersType } from '@/components/view/installer/batch-payments/all.type';
import { listPagination } from '@/components/common/list';
import initList from '@/components/view/installer/batch-payments/init-list';
import SecondaryNav from '@/components/common/secondary-nav/index.vue';
import initData from '@/components/view/installer/batch-payments/init-data';
import { account } from '@/data';
import router, {
    communityBaseRouter, communitySubRouter
} from '@/router';
import { payProcessing } from '@/components/view/common/payment';

export default defineComponent({
    components: {
        listPagination,
        SecondaryNav,
        payProcessing
    },
    props: {
        type: {
            type: String as PropType<HeadersType>,
            default: 'community'
        },
        id: {
            type: String,
            default: ''
        }
    },
    setup(props) {
        // eslint-disable-next-line vue/no-setup-props-destructure
        const propProjectType = props.type;
        // eslint-disable-next-line vue/no-setup-props-destructure
        const projectId = props.id;

        const {
            navs,
            activeNavItem,
            changePath
        } = initData(propProjectType, projectId);

        const {
            typeOptions,
            statusOptions,
            projectOption
        } = initSearch(propProjectType);

        const {
            headers,
            updateToList,
            listRequestData,
            action,
            successPay,
            goInfo,
            isShowPayProcessing,
            payJumpUrl
        } = initList(propProjectType, projectId);

        const backProject = () => {
            account.changePCIdentity(localStorage.getItem('Community'), (res: {
                Token: string;
                Grade: string;
            }) => {
                localStorage.setItem('token', res.Token);
                localStorage.setItem('grade', res.Grade);
                router.push(`/${communityBaseRouter}/${communitySubRouter.residentDevice}`);
            });
        };

        return {
            headers,
            typeOptions,
            statusOptions,
            updateToList,
            listRequestData,
            action,
            successPay,
            goInfo,
            isShowPayProcessing,
            navs,
            activeNavItem,
            changePath,
            projectOption,
            backProject,
            payJumpUrl
        };
    }
});
