import { ref, Ref } from 'vue';
import {
    getSearch,
    ListActionItem, ListHeaderItem, RequestData
} from '@/components/common/list';
import noticeBase from '@/util/notice.base';
import { cancel } from '@/components/view/common/payment';
import { ChangeWordList } from '@/methods/setText';
import { HeadersType } from '@/components/view/installer/batch-payments/all.type';
import router, { installerBaseRouter, installerBatchRouter } from '@/router';

const headerBase: Array<ListHeaderItem> = [
    {
        name: 'OrderNumber',
        label: WordList.OrderOrderNumber
    }, {
        name: 'Projects',
        label: WordList.ProperAllCommunity,
        type: 'customize'
    }, {
        name: 'Type',
        label: WordList.TabelKeyInHtmlType
    }, {
        name: 'AptNumber',
        label: ChangeWordList.PaymentsNumInList
    }, {
        name: 'TotalPrice',
        label: WordList.OrderTotalPrice
    }, {
        name: 'Status',
        label: WordList.MulListUpdateListTanleStatus
    }, {
        name: 'CreateTime',
        label: WordList.TabelMessageBoxCreateTime
    }];

const initList = (
    projectType: HeadersType,
    projectId: string
) => {
    const url = projectType === 'community' ? 'v3/web/community/order/getList' : 'v3/web/office/order/getList';
    const listRequestData: Ref<RequestData> = ref({
        url,
        param: getSearch(
            {
                // ProjectType: projectType === 'community' ? 'multiple' : 'office',
                // IsBatch: 1,
                Community: projectId || 'all',
                Type: 'all',
                Status: 'all',
                Key: ''
            }
        )
    });
    const updateToList = ref(0);

    const isShowPayProcessing = ref(false);
    const payJumpUrl = ref('');
    const action: ListActionItem[] = [{
        type: 'pay',
        event(val: { bmurl: string }) {
            isShowPayProcessing.value = true;
            payJumpUrl.value = val.bmurl;
            window.open(val.bmurl);
        },
        class: 'el-icon-my-pay modify-icon',
        showCondition(val: { StatusEnum: string }) {
            return val.StatusEnum === '0';
        },
        title: WordList.OrderPay
    },
    {
        type: 'cancelpay',
        event(val: {OrderNumber: string; BmOrderNumber: string; PayCode: string}) {
            noticeBase.messageBox('confirm', WordList.TabelPaymentCancel, WordList.TabelDeleteTitle, 'warning')(() => {
                cancel.cancelOrder({ OrderNumber: val.OrderNumber }, () => {
                    updateToList.value += 1;
                });
            });
        },
        class: 'el-icon-my-cancel-payment cancelpay-icon',
        showCondition(val: { StatusEnum: string }) {
            return val.StatusEnum === '0';
        },
        title: WordList.TabelFootCancel
    }];

    const headers = (() => {
        headerBase.splice(1, 1);
        if (projectType === 'community') {
            headerBase.splice(1, 0,
                {
                    name: 'Projects',
                    label: WordList.ProperAllCommunity,
                    type: 'customize'
                });
        } else {
            headerBase.splice(1, 0,
                {
                    name: 'Projects',
                    label: WordList.ProperAllOffice
                });
        }
        return headerBase;
    })();

    const successPay = () => {
        isShowPayProcessing.value = false;
        updateToList.value += 1;
    };
    const goInfo = (value: { ID: string }) => {
        router.push({
            path: `/${installerBaseRouter}/${installerBatchRouter.batchPaymentInfo}`,
            query: {
                id: value.ID,
                ProjectType: projectType
            }
        });
    };

    return {
        headers,
        updateToList,
        listRequestData,
        action,
        successPay,
        goInfo,
        isShowPayProcessing,
        payJumpUrl
    };
};
export default initList;
