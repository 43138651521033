// 标签卡数据
import router, { installerBaseRouter, installerBatchRouter } from '@/router';
import { computed } from 'vue';
import { HeadersType } from '@/components/view/installer/batch-payments/all.type';
import { account, community } from '@/data';
import changeTextByScene from '@/methods/setText';

const navs = [
    {
        label: WordList.ProperAllCommunity,
        key: 'community',
        path: `/${installerBaseRouter}/${installerBatchRouter.batchPayments}?type=community`
    }, {
        label: WordList.ProperAllOffice,
        key: 'office',
        path: `/${installerBaseRouter}/${installerBatchRouter.batchPayments}?type=office`
    }
];

const initData = (
    type: HeadersType,
    projectId: string
) => {
    // 激活的标签
    const activeNavItem = computed(() => type);

    // 标签切换操作
    const changePath = (key: 'community'|'office', path: string) => {
        const grade = key === 'community' ? account.communityRole : account.officeRole;
        localStorage.setItem('grade', String(grade));
        changeTextByScene();
        community.isRefreshProjectList.value += 1;
        community.isFirstRefresh.value = true;
        router.push(`${path}${projectId !== '' ? `&id=${projectId}` : ''}`);
    };
    return {
        navs,
        activeNavItem,
        changePath
    };
};
export default initData;