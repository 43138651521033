import { HeadersType } from '@/components/view/installer/batch-payments/all.type';
import { ref } from 'vue';
import subscriptionApi from '@/api/common/subscription';

const communityTypeOptions = [
    {
        value: 'all',
        label: WordList.SelectAll
    }, {
        value: '1',
        label: WordList.ProperAllTextActivate
    }, {
        value: '2',
        label: WordList.ProperAllTextRenew
    }, {
        value: '3',
        label: WordList.AdditionalApp
    }];

const officeTypeOptions = [
    {
        value: 'all',
        label: WordList.SelectAll
    }, {
        value: '1',
        label: WordList.ProperAllTextActivate
    }, {
        value: '2',
        label: WordList.ProperAllTextRenew
    }, {
        value: '5',
        label: WordList.ProperAllTextFeature
    }];

const statusOptions = [
    {
        value: 'all',
        label: WordList.SelectAll
    }, {
        value: '0',
        label: WordList.ProperAllTextProcessing
    }, {
        value: '1',
        label: WordList.ProperAllTextSuccessed
    }, {
        value: '2',
        label: WordList.PropertyAllTextFailed
    }, {
        value: '3',
        label: WordList.ProperAllTextTimeOut
    }, {
        value: '5',
        label: WordList.TabelFootCancel
    }, {
        value: '6',
        label: WordList.ProperAllTextSystemProcessing
    }];

const initSearch = (
    projectType: HeadersType
) => {
    let typeOptions;
    const projectOption = ref<{Name: string;ID: string}[]>([]);
    const projectHandle = (res: {row: {Location: string;ID: string}[]}) => {
        res.row.forEach((project) => {
            projectOption.value.push({
                Name: project.Location,
                ID: project.ID
            });
        });
    };

    if (projectType === 'community') {
        typeOptions = communityTypeOptions;
        subscriptionApi.getCommunityList({}, projectHandle);
    } else {
        typeOptions = officeTypeOptions;
        subscriptionApi.getOfficeList(projectHandle);
    }

    return {
        typeOptions,
        statusOptions,
        projectOption
    };
};
export default initSearch;
